import styles from './ClosedBetaModal.module.scss';
import { ButtonGradient } from '@/components/ButtonGradient';
import { Modal } from '@/components/Modal';
import { useDispatch } from 'react-redux';
import { setClosedBetaModalActive } from '@/store';
import { useLocale } from 'next-intl';
import { TELEGRAM_CHAT_RU_URL, TELEGRAM_CHAT_URL } from '@/constants';

export const ClosedBetaModal = () => {
  const dispatch = useDispatch();
  const locale = useLocale();

  const handleButtonClick = async () => {
    if (locale === 'ru') {
      window.open(TELEGRAM_CHAT_RU_URL, '_blank');
    } else {
      window.open(TELEGRAM_CHAT_URL, '_blank');
    }
  };

  const handleClose = () => {
    dispatch(setClosedBetaModalActive(false));
  };

  return (
    <Modal
      opened={true}
      closeFn={handleClose}
      className={styles.modal}
      hiddenButton
    >
      <div className={styles.container}>
        <div className={styles.content}>
          <h1 className={styles.title}>Join Closed Beta Test!</h1>
        </div>
        <div className={styles.footer}>
          <span className={styles.desc}>
            You&apos;re almost there! Join the chat,
            <br />
            get a referral, and control your
            <br />
            adventure
          </span>
          <ButtonGradient onClick={handleButtonClick}>
            Ask for invite link
          </ButtonGradient>
        </div>
        <img
          src="/png/closedBeta.png"
          alt="keya"
          className={styles.background}
        />
      </div>
    </Modal>
  );
};
